import { default as _91name_93_45raion3zvW8xFYnBMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[name]-raion.vue?macro=true";
import { default as _91name_93_45raion9H8Zu8l1WwMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/[name]-raion.vue?macro=true";
import { default as _91space_id_9343TGr7W1vJMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/[space_id].vue?macro=true";
import { default as indexNtX1lbtWr2Meta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/index.vue?macro=true";
import { default as metro_45_91slug_93EPfNcdG0SlMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/metro-[slug].vue?macro=true";
import { default as pod_45_91subtype_93BjDK4gZbzsMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/pod-[subtype].vue?macro=true";
import { default as indexEOosXIUoWYMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/index.vue?macro=true";
import { default as indexVsxlYXRX0VMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/about/index.vue?macro=true";
import { default as _91id_93D5VzSvYqQQMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/articles/[id].vue?macro=true";
import { default as indexJEkYAsje6JMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/articles/index.vue?macro=true";
import { default as _91slug_id_93oxW2NqxP5vMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/bc/[slug_id].vue?macro=true";
import { default as _91id_93XW69ICDXi2Meta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/bci/[id].vue?macro=true";
import { default as indexTTyu04Fq2kMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/build/[id]/index.vue?macro=true";
import { default as print5chF9KYOU2Meta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/build/[id]/print.vue?macro=true";
import { default as bc0TNLRBLQ5YMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/bc.vue?macro=true";
import { default as gabE79PBniuQQMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/gab.vue?macro=true";
import { default as index4FHNLj5y5OMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/index.vue?macro=true";
import { default as nmaDpRIx7wdmKMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/nma.vue?macro=true";
import { default as oszJP27uTWVCtMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/osz.vue?macro=true";
import { default as _91space_id_93bE3MAwJ0CjMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/place/[space_id].vue?macro=true";
import { default as indexaABsuOsyETMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/contacts/index.vue?macro=true";
import { default as _91id_93uqggfBHOVaMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/docs/[id].vue?macro=true";
import { default as indexXdtBQwwaxEMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/docs/index.vue?macro=true";
import { default as index5MsS4PrQbsMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/favorites/index.vue?macro=true";
import { default as indexzEPs8b6LjbMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/index.vue?macro=true";
import { default as _91id_9366sQVByEr7Meta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/news/[id].vue?macro=true";
import { default as indexV0EzknhCDeMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/news/index.vue?macro=true";
import { default as index32DNDLTE8qMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/offer/[id]/index.vue?macro=true";
import { default as printClWeHBhjI0Meta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/offer/[id]/print.vue?macro=true";
import { default as component_45stubqvkZGLfQ7ZMeta } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubqvkZGLfQ7Z } from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "offertype-name-raion",
    path: "/:offertype()/:name()-raion",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[name]-raion.vue")
  },
  {
    name: "offertype-type-name-raion",
    path: "/:offertype()/:type()/:name()-raion",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/[name]-raion.vue")
  },
  {
    name: "offertype-type-space_id",
    path: "/:offertype()/:type()/:space_id()",
    meta: _91space_id_9343TGr7W1vJMeta || {},
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/[space_id].vue")
  },
  {
    name: "offertype-type",
    path: "/:offertype()/:type()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/index.vue")
  },
  {
    name: "offertype-type-metro-slug",
    path: "/:offertype()/:type()/metro-:slug()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/metro-[slug].vue")
  },
  {
    name: "offertype-type-pod-subtype",
    path: "/:offertype()/:type()/pod-:subtype()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/[type]/pod-[subtype].vue")
  },
  {
    name: "offertype",
    path: "/:offertype()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/[offertype]/index.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/about/index.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/articles/[id].vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/articles/index.vue")
  },
  {
    name: "bc-slug_id",
    path: "/bc/:slug_id()",
    meta: _91slug_id_93oxW2NqxP5vMeta || {},
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/bc/[slug_id].vue")
  },
  {
    name: "bci-id",
    path: "/bci/:id()",
    meta: _91id_93XW69ICDXi2Meta || {},
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/bci/[id].vue")
  },
  {
    name: "build-id",
    path: "/build/:id()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/build/[id]/index.vue")
  },
  {
    name: "build-id-print",
    path: "/build/:id()/print",
    meta: print5chF9KYOU2Meta || {},
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/build/[id]/print.vue")
  },
  {
    name: "catalog-bc",
    path: "/catalog/bc",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/bc.vue")
  },
  {
    name: "catalog-gab",
    path: "/catalog/gab",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/gab.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/index.vue")
  },
  {
    name: "catalog-nma",
    path: "/catalog/nma",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/nma.vue")
  },
  {
    name: "catalog-osz",
    path: "/catalog/osz",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/osz.vue")
  },
  {
    name: "catalog-place-space_id",
    path: "/catalog/place/:space_id()",
    meta: _91space_id_93bE3MAwJ0CjMeta || {},
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/catalog/place/[space_id].vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/contacts/index.vue")
  },
  {
    name: "docs-id",
    path: "/docs/:id()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/docs/[id].vue")
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/docs/index.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/favorites/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/index.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/news/index.vue")
  },
  {
    name: "offer-id",
    path: "/offer/:id()",
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/offer/[id]/index.vue")
  },
  {
    name: "offer-id-print",
    path: "/offer/:id()/print",
    meta: printClWeHBhjI0Meta || {},
    component: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/pages/offer/[id]/print.vue")
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-otdelno-stoyashchego-zdaniya",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/Zemelniy-uchastok-prodaja",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/Prodaja-ofisa",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-proizvodstva",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/Prodaja-skladov",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodaja_psn",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/torgovaja-plosad",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodazha-kliniki",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodazha-kafe",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodazha-banka",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodazha-sportivnogo-zala",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodazha-gostinicy",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodazha-avtoservisa",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodazha-salona-krasoty",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/prodaja/prodazha-psn/prodaja-magazina",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-ofisa",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/Arenda-skladov",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/Zemelniy-uchastok-arenda",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-otdelno-stoyashchego-zdaniya",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-proizvodstva",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda_psn",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-torgovyh-ploshchadej",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-magazina",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-kliniki",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-kafe",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-banka",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-fitnes-zala",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-avtoservisa",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-gostinicy",
    component: component_45stubqvkZGLfQ7Z
  },
  {
    name: component_45stubqvkZGLfQ7ZMeta?.name,
    path: "/arenda/arenda-psn/arenda-salona-krasoty",
    component: component_45stubqvkZGLfQ7Z
  }
]