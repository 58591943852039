export default defineAppConfig({
    arenda: {
        slug: 'arenda',
        header: 'аренда',
        title: 'Аренда коммерческой недвижимости',
        types: [
            { 
                slug: "ofisy", 
                vin: "офисных помещений", 
                label: "офисные помещения", 
                image: "Офис_0.jpg" 
            },
            { 
                slug: "sklady", 
                vin: "складских помещений", 
                label: "складские помещения", 
                image: "Склад_00.jpg" 
            },
            { 
                slug: "vstroenniy-pomeshenia", 
                vin: "встроенных помещений", 
                label: "встроенные помещения", 
                image: "Встроенные_0.jpg" 
            },
            { 
                slug: "proizvodstvo", 
                vin: "производственных помещений", 
                label: "производственные помещения", 
                image: "Производство_0.jpg" 
            },
            { 
                slug: "zemelnye-uchastki", 
                vin: "земельных участков", 
                label: "земельные участки", 
                image: "Земельные участки_0.jpg" 
            },
            { 
                slug: "otdelno-stoyahie-zdaniya", 
                vin: "отдельно стоящих зданий", 
                label: "Здания (ОСЗ)", 
                image: "ОСЗ_0.jpg" 
            },
            
        ],
        prices_units: [
            { id: 'kvm', label: 'кв.м.', default: false },
            { id: 'mes', label: 'месяц', default: true },
        ],
        fields: {
            arenda_kv_m: 3539,
            arenda_mes: 3512,
        }
    },
    prodaja: {
        slug: 'prodaja',
        header: 'продажа',
        title: 'Продажа коммерческой недвижимости',
        types: [
            { 
                slug: "otdelno-stoyahie-zdaniya", 
                vin: "отдельно стоящих зданий", 
                label: "Здания (ОСЗ)", 
                image: "ОСЗ_0.jpg" 
            },
            { 
                slug: "vstroenniy-pomeshenia", 
                vin: "встроенных помещений", 
                label: "встроенные помещения", 
                image: "Встроенные_0.jpg" 
            },
            { 
                slug: "zemelnye-uchastki", 
                vin: "земельных участков", 
                label: "земельные участки", 
                image: "Земельные участки_0.jpg" 
            },
            { 
                slug: "ofisy", 
                vin: "офисных помещений", 
                label: "офисные помещения", 
                image: "Офис_0.jpg" 
            },
            { 
                slug: "proizvodstvo", 
                vin: "производственных помещений", 
                label: "производственные помещения", 
                image: "Производство_0.jpg" 
            },
            { 
                slug: "sklady", 
                vin: "складских помещений", 
                label: "складские помещения", 
                image: "Склад_00.jpg" 
            }
        ],
        prices_units: [
            { id: 'kvm', label: 'кв.м.', default: false },
            { id: 'vse', label: 'всё', default: true },
        ],
        fields: {
            arenda_kv_m: 856,
            arenda_vse: 491,
        }
    },
    naznacheniya: [
        { header: 'Под офис', image: '/img/catalog/office.jpg', slug: 'pod-ofis' },
        { header: 'Свободного назначения', image: '/img/catalog/empty place.jpg', slug: 'pod-svobodnoe-naznachenie' },
        { header: 'Для торговли', image: '/img/catalog/market.jpg', slug: 'pod-torgovly' },
        { header: 'Под производство', image: '/img/catalog/factory.jpg', slug: 'pod-proizvodstvo' },
        { header: 'Под салон красоты', image: '/img/catalog/beauty_salon.jpg', slug: 'pod-salon-krasoty' },
        { header: 'Для кафе или общепита', image: '/img/catalog/cafe.jpg', slug: 'pod-obshchepit' },
        { header: 'Под банк', image: '/img/catalog/bank.jpg', slug: 'pod-bank' },
        { header: 'Под спортивный бизнес', image: '/img/catalog/gym.jpg', slug: 'pod-sportivnyi-biznes' },
        { header: 'Под склад', image: '/img/catalog/Building.jpg', slug: 'pod-sklad' },
        { header: 'Под медицинский центр', image: '/img/catalog/hospital.jpg', slug: 'pod-medetcinskiy-center' },
        { header: 'Для автобизнеса', image: '/img/catalog/cars.jpg', slug: 'pod-avtobiznes' },
        { header: 'Под гостиницу', image: '/img/catalog/Hotel.jpg', slug: 'pod-gostinnitcu' },
    ],
    offer_type_associative: {
        "Здание" : "здания",
        "ПСН" : "помещения свободного назначения",
        "офис" : "офиса",
        "торговое" : "торгового помещения",
        "Сфера услуг" : "помещения",
        "" : "помещения",
        "общепит" : "помещения",
        "Производство" : "производственного помещения",
        "Склад" : "складского помещения",
        "Другое" : "помещения",
        "Земельный участок" : "земельного участка",
        "Автосервис" : "помещения под автобизнес",
        "Спорт" : "помещения под спорт",
        "Гостиница(Хостел и др.)" : "аппартментов"
    },
    city: {
        im: 'Санкт-Петербург+ЛО', // Именительный
        rod: 'Санкт-Петербурга', // Родительный
        dat: 'Санкт-Петербургу', // Дательный
        tvo: 'Санкт-Петербургом', // Творительный
        pre: 'Санкт-Петербурге' // Предложный
    },
    contacts: {
        phone: '+7 (812) 987-76-31',
        phone_link: 'tel:88129877631',
        email: 'info@arendabc.ru',
        email_link: 'mailto:info@arendabc.ru',
    },
    seo:{
        index: false
    },
    catalog: [
        {link: '/catalog/bc', label: 'Каталог бизнес-центров', image: '/img/bc-promo.jpg'},
        {link: '/catalog/nma', label: 'Коммерческие новостройки', image: '/img/tc-promo.jpg'},
        {link: '/catalog/osz', label: 'Каталог зданий', image: '/img/osz-promo.jpg'},
        {link: '/catalog/gab', label: 'Готовый Арендный Бизнес', image: '/img/gab.jpg'},
    ]
})