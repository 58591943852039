<script setup>
const app_config = useAppConfig()

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';

const modules = ref([FreeMode])

const swipperBreakpoints = {
    '0': {
        slidesPerView: 1.7,
        spaceBetween: 15,
    },
    '380': {
        slidesPerView: 1.9,
        spaceBetween: 15,
    },
    '420': {
        slidesPerView: 2.1,
        spaceBetween: 15,
    },
    '500': {
        slidesPerView: 2.5,
        spaceBetween: 20,
    },
    '600': {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    '804': {
        slidesPerView: 4,
        spaceBetween: 20,
    },
    '980': {
        slidesPerView: 5,
        spaceBetween: 25,
    },
    '1240': {
        slidesPerView: 6,
        spaceBetween: 25,
    },
}

</script>
<template>
    <!-- <div class="grid md:grid-cols-3 grid-cols-1 w-full gap-6 mt-16">
        <nuxt-link to="/catalog/bc" class="relative rounded-xl cursor-pointer w-full h-[160px] group flex items-center justify-center z-[6]">
            <div class="absolute rounded-xl w-full h-full bg-[url('/img/bc-promo.jpg')]  bg-no-repeat bg-cover bg-center z-0 group-hover:brightness-75 brightness-50"></div>
            <span class="text-white font-bold text-xl z-10">Каталог бизнес-центров</span>
        </nuxt-link>
        <nuxt-link to="/catalog/nma" class="relative rounded-xl  cursor-pointer w-full h-[160px] group flex items-center justify-center z-[6]">
            <div class="absolute rounded-xl w-full h-full bg-[url('/img/tc-promo.jpg')] bg-no-repeat bg-cover bg-center z-0 group-hover:brightness-75 brightness-50"></div>
            <span class="text-white font-bold text-xl z-10">Коммерческие новостройки</span>
        </nuxt-link>
        <nuxt-link to="/catalog/osz" class="relative rounded-xl cursor-pointer w-full h-[160px] group flex items-center justify-center z-[6]">
            <div class="absolute rounded-xl w-full h-full bg-[url('/img/osz-promo.jpg')] bg-no-repeat bg-cover bg-center z-0 group-hover:brightness-75 brightness-50"></div>
            <span class="text-white font-bold text-xl z-10">Каталог зданий</span>
        </nuxt-link>
    </div> -->

    <div class="md:grid md:grid-cols-4 grid-cols-1 w-full gap-6 hidden mt-6">
        <div v-for="( item, index ) in app_config.catalog">
            <div>
                <nuxt-link :to="`${item.link}`"
                    class="flex items-center justify-center w-full h-[160px] group md:hover:scale-[1.1] md:hover:z-10 transition-transform">
                    <div class="relative brightness-50 w-full h-full md:group-hover:brightness-100">
                        <div :style="{ backgroundImage: `url('${item.image}')` }"
                            class="rounded-xl w-full h-full bg-no-repeat bg-cover bg-center">
                        </div>
                    </div>
                    <div
                        class="text-white font-bold text-xl rounded-lg px-4 absolute z-10 group-hover:bg-[#ffffffe3] group-hover:text-brand-grey ">
                        {{ item.label }}
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
    <div class="md:hidden block mt-6">
        <swiper :slidesPerView="6" :spaceBetween="30" :modules="modules" class="mySwiper"
            :breakpoints="swipperBreakpoints">
            <swiper-slide v-for="item in app_config.catalog" class=" ">
                <nuxt-link :to="`${item.link}`"
                    class="overflow-hidden min-h-[211px] w-[170px] h-full relative rounded-xl bg-cover bg-no-repeat flex flex-col justify-end cursor-pointer hover:brightness-100 brightness-[.85] "
                    :style="{ backgroundImage: `url('${item.image}')` }">
                    <div class="bg-[#2C2C2CB8] rounded-b-2xl p-2 text-white h-[60px] z-10">
                        <p class="text-sm text-center flex flex-column justify-center items-center h-full"> {{
                            item.label }}
                        </p>
                    </div>
                </nuxt-link>
            </swiper-slide>
        </swiper>
    </div>
</template>